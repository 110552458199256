<template>
  <section v-if="theater" class="theater-billboard-page">
    <div class="theater-detail-header">
      <div class="container">
        <div class="columns is-multiline is-vcentered">
          <div class="column is-12">
            <h2 class="title">{{ theater.nombre }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="theater-detail-info">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="banner">
              <figure class="image">
                <img :src="theater.portada ? theater.portada : 'https://desarrollo.voyalteatro.mx/backend/api/img/render/9339'" />
              </figure>
            </div>
          </div>
          <div class="column is-12">
            <div class="description">
              <h4 class="subtitle">{{ translatedText('theaters_direction') }}</h4>
              <p>{{ theater.direccion }}</p>
            </div>
          </div>
          <div v-if="theater.telefono && theater.telefono != ''" class="column is-12">
            <div class="description">
              <h4 class="subtitle">{{ translatedText('theaters_phone') }}</h4>
              <p>{{ theater.telefono }}</p>
            </div>
          </div>
          <div v-if="theater.mapa_url" class="column is-12">
            <div class="map">
              <iframe :src="theater.mapa_url" class="w-100"></iframe>
            </div>
          </div>
          <div v-if="theater.rutas_camiones" class="column is-12">
            <div class="maps-actions">
              <b-button class="is-medium vat-btn has-text-white is-3" tag="a" target="_blank" :href="theater.rutas_camiones" type="is-info">
                {{ translatedText('theaters_view_routes') }}
              </b-button>
              <b-button
                class="is-medium vat-btn has-text-white is-3"
                tag="a"
                target="_blank"
                :href="'https://www.google.com/maps/search/?api=1&query=' + theater.lat + ',' + theater.lon"
                type="is-info"
              >
                {{ translatedText('theaters_open_routes') }}
              </b-button>
            </div>
          </div>
          <div v-else-if="theater.lat" class="column is-12">
            <div class="maps-actions">
              <b-button
                class="is-medium vat-btn has-text-white is-3"
                tag="a"
                target="_blank"
                :href="'https://rutasgdl.com/#!ratio=100&lat=' + theater.lat + '&lon=' + theater.lon + '&z=16'"
                type="is-info"
              >
                {{ translatedText('theaters_view_routes') }}
              </b-button>
              <b-button
                class="is-medium vat-btn has-text-white is-3"
                tag="a"
                target="_blank"
                :href="'https://www.google.com/maps/search/?api=1&query=' + theater.lat + ',' + theater.lon"
                type="is-info"
              >
                {{ translatedText('theaters_open_routes') }}
              </b-button>
            </div>
          </div>
          <div v-if="theater.iconografias && theater.iconografias.length > 0" class="column is-12">
            <div class="iconography">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <h4 class="subtitle">{{ translatedText('theaters_iconography') }}</h4>
                </div>
                <div v-for="iconografia in theater.iconografias" :key="iconografia.id" class="column is-6">
                  <article class="media">
                    <figure class="media-left">
                      <p class="image is-64x64">
                        <img :src="iconografia.portada" />
                      </p>
                    </figure>
                    <div class="media-content">
                      <div class="content">
                        <p>{{ iconografia.nombre }}</p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Theater',
  props: {
    theater: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.ui.isMobile,
    }),
  },
};
</script>
