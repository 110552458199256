<template>
  <section class="form-events">
    <div class="modal" :class="{ 'is-active': functionModalSelect }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ translatedText('select_showing') }}</p>
          <button @click="functionModalSelect = false" class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <div v-for="fecha in fechasEvent" :key="fecha.id" class="modal-dates">
            <h2>{{ formatDate(fecha.fecha) }}</h2>
            <div class="buttons">
              <b-button
                v-for="funcion in fecha.funciones"
                :key="funcion.id"
                type="is-info"
                outlined
                rounded
                @click="
                  seleccionarFuncion(fecha.id, funcion.id);
                  functionModalSelect = false;
                "
              >
                {{ funcion.hora }} h
              </b-button>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot"></footer>
      </div>
    </div>

    <div class="modal" :class="{ 'is-active': seatsnModalSelect }">
      <div class="modal-background"></div>
      <div class="modal-card seats-modal-size">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ translatedText('select_seats') }}</p>
          <button @click="seatsnModalSelect = false" class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-multiline">
            <div class="column is-9">
              <div class="localities">
                <ImageZoom v-if="seatsnModalSelect" :imageUrl="event.localidad" />
              </div>
            </div>
            <div class="column is-3">
              <div class="select-seats-list">
                <div v-for="(boleto, index) in boletos_por_asignar_asientos" :key="index">
                  <b-field :label="translatedText('ticket') + (index + 1) + ' - ' + boleto.boleto" type="is-info">
                    <b-select
                      :id="'select-asiento-' + boleto.boletos_id + '-' + index"
                      :placeholder="translatedText('select_seat_placeholder')"
                      rounded
                      expanded
                      required
                    >
                      <option v-for="asiento in filteredAsientos(boleto.asientos)" :key="asiento.id" :value="asiento.id">
                        {{ asiento.fila + ' - ' + asiento.columna }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button @click="seatsnModalSelect = false" class="button">{{ translatedText('cancel') }}</button>
          <button @click="guardarAsientos" class="button is-info">{{ translatedText('save') }}</button>
        </footer>
      </div>
    </div>

    <div v-if="ticketModal" class="modal billboard-modal-ticket" :class="{ 'is-active': ticketModal }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ translatedText('enjoy_showing') }}</p>
          <button @click="ticketModal = false" class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-centered is-multiline">
            <div class="column is-4">
              <figure class="image">
                <img :src="ticketViewModal.qr" />
              </figure>
            </div>
            <div class="column is-12 has-text-centered">
              <p class="">
                <strong>{{ translatedText('event') }}:</strong> {{ ticketViewModal.obra.titulo }}
              </p>
              <p class="">
                <strong>{{ translatedText('date') }}:</strong> {{ ticketViewModal.fecha }}
              </p>
              <p class="">
                <strong>{{ translatedText('time') }}:</strong> {{ ticketViewModal.hora }}
              </p>
              <p class="">
                <strong>{{ translatedText('place') }}:</strong> {{ ticketViewModal.lugar }}
              </p>
              <p class="">
                <strong>{{ translatedText('cost') }}:</strong> {{ formatCosto(ticketViewModal.costo) }}
              </p>
              <br />
            </div>
            <div class="column is-12 has-text-centered">
              <p style="margin-bottom: 0">
                <strong>{{ translatedText('tickets') }}:</strong>
              </p>
              <div v-for="entrada in ticketViewModal.entradas" :key="entrada.descripcion">
                <p class="clamp-1" style="margin: 0">
                  {{ entrada.descripcion + ' (' + entrada.cantidad + ')' }}
                </p>
                <div v-if="entrada.asientos">
                  <p class="clamp-1" style="margin: 0" v-for="asiento in entrada.asientos" :key="asiento.descripcion">
                    {{ asiento.fila + '-' + asiento.numero }}
                  </p>
                </div>
              </div>
            </div>
            <br />
            <div class="column is-12">
              <p class="has-text-centered">
                {{ translatedText('confirmation_code') }} <br />
                <strong>{{ ticketViewModal.codigo }}</strong>
              </p>
              <br />
              <p class="has-text-centered">
                <strong>{{ translatedText('present_this_ticket') }}</strong>
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div v-if="showFormPayment" class="card">
      <header class="card-header">
        <button v-if="currentStep > 1" @click="prevStep" class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i class="fas fa-angle-left" aria-hidden="true"></i>
          </span>
        </button>
        <p class="card-header-title">{{ titleCard }}</p>
      </header>
      <div class="card-content">
        <div v-if="event.proximamente == 1">
          <h2 class="has-text-centered is-size-4">
            <strong>{{ translatedText('coming_soon_for_sale') }}</strong>
          </h2>
        </div>
        <div v-else-if="event.estatus == 0 || event.virtual == 1" class="steps">
          <div v-if="currentStep === 1">
            <div v-if="event.virtual == '1' && event.virtual_opciones == 'RANGO'" class="select-dates">
              <b-field>
                <p class="has-text-centered has-text-weight-semibold">Inicio: {{ formatearFechaRango(event.fecha_inicio) }}</p>
              </b-field>
              <b-field>
                <p class="has-text-centered has-text-weight-semibold">Fin: {{ formatearFechaRango(event.fecha_fin) }}</p>
              </b-field>
            </div>
            <div v-else class="select-dates">
              <b-field :label="translatedText('choose_date')">
                <b-input :value="fecha_label_completa" expanded disabled></b-input>
              </b-field>
              <b-button @click="functionModalSelect = true" type="is-info" rounded expanded>{{ translatedText('see_more_dates') }}</b-button>
            </div>
            <div class="separate"></div>
            <div class="select-tickets">
              <div class="ticket" v-for="boleto in boletos_disponibles_funcion" :key="boleto.id">
                <div class="columns is-multiline is-mobile is-vcentered">
                  <div class="column is-half-desktop is-full-mobile is-full-tablet">
                    <div class="ticket-label">
                      <h3>{{ boleto.nombre }}</h3>
                      <p>{{ '$' + parseFloat(boleto.precio).toFixed(2) }}</p>
                    </div>
                    <div class="ticket-comment">
                      <p>{{ boleto.descripcion }}</p>
                    </div>
                  </div>
                  <div class="column is-half-desktop is-full-mobile is-full-tablet">
                    <div class="ticket-quantity">
                      <b-field>
                        <b-numberinput
                          :value="getCantidadBoletosSeleccionados(boleto.id)"
                          min="0"
                          rounded
                          :editable="false"
                          @input="handleCantidadBoletosChange(boleto, $event)"
                        ></b-numberinput>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="separate"></div>
            <div class="totals">
              <p>{{ translatedText('subtotal') }}:</p>
              <p>$ {{ subtotal }}</p>
            </div>
            <div class="actions">
              <b-button v-if="boletos_seleccionados.length > 0" @click="nextStep" type="is-info" rounded expanded>{{
                translatedText('next')
              }}</b-button>
            </div>
          </div>
          <div v-else-if="currentStep === 2">
            <div class="confirmate-order">
              <h3>{{ translatedText('tickets') }}:</h3>
              <div class="confirm-order-tickets">
                <p v-for="boleto in boletos_seleccionados" :key="boleto.boletos_id">{{ boleto.boleto }} ({{ boleto.cantidad }})</p>
              </div>
              <div v-if="event.virtual == '1' && event.virtual_opciones == 'RANGO'">
                <h3>
                  Inicio: <span>{{ formatearFechaRango(event.fecha_inicio) }}</span>
                </h3>
                <h3>
                  Fin: <span>{{ formatearFechaRango(event.fecha_fin) }}</span>
                </h3>
              </div>
              <div v-else>
                <h3>
                  {{ translatedText('date') }}: <span>{{ fecha_label }}</span>
                </h3>
                <h3>
                  {{ translatedText('time') }}: <span>{{ hora_label }}</span>
                </h3>
              </div>
              <h3>
                {{ translatedText('place') }}: <span>{{ event.teatro }}</span>
              </h3>
            </div>
            <div class="separate"></div>
            <div class="have-cupon">
              <h3>{{ translatedText('have_coupon') }}</h3>
              <p>{{ translatedText('redeem_here') }}</p>
              <b-field>
                <b-input v-model="cuponInput" type="is-info" placeholder="CUPON2024" rounded></b-input>
              </b-field>
              <b-button @click="applyCupon" type="is-default" rounded expanded>{{ translatedText('apply_coupon') }}</b-button>
            </div>
            <div class="separate"></div>
            <div class="sub-totals">
              <div class="sub-row">
                <p>{{ translatedText('subtotal') }}:</p>
                <p>$ {{ subtotal }}</p>
              </div>
              <div v-if="descuento > 0" class="sub-row">
                <p>{{ translatedText('discount') }}:</p>
                <p>- $ {{ parseFloat(descuento).toFixed(2) }}</p>
              </div>
              <div v-if="total > 0" class="sub-row">
                <p>{{ translatedText('handling_fee') }}:</p>
                <p>$ {{ comisiones }}</p>
              </div>
            </div>
            <div class="separate"></div>
            <div class="totals">
              <p>{{ translatedText('total') }}:</p>
              <p>$ {{ total }}</p>
            </div>
            <div class="actions">
              <b-button @click="nextStep" type="is-info" rounded expanded>{{ translatedText('next') }}</b-button>
            </div>
          </div>
          <div v-else-if="currentStep === 3">
            <div class="confirmate-order">
              <h3>{{ translatedText('tickets') }}:</h3>
              <div class="confirm-order-tickets">
                <div v-for="boleto in boletos_seleccionados" :key="boleto.boletos_id">
                  <p>{{ boleto.boleto }} ({{ boleto.cantidad }}) {{ asientosTexto(boleto.asientos) }}</p>
                </div>
              </div>
              <h3>
                {{ translatedText('date') }}: <span>{{ fecha_label }}</span>
              </h3>
              <h3>
                {{ translatedText('time') }}: <span>{{ hora_label }}</span>
              </h3>
              <h3>
                {{ translatedText('place') }}: <span>{{ event.teatro }}</span>
              </h3>
            </div>
            <div class="separate"></div>
            <div class="sub-totals">
              <div class="sub-row">
                <p>{{ translatedText('subtotal') }}:</p>
                <p>$ {{ subtotal }}</p>
              </div>
              <div v-if="descuento > 0" class="sub-row">
                <p>{{ translatedText('discount') }}:</p>
                <p>- $ {{ parseFloat(descuento).toFixed(2) }}</p>
              </div>
              <div v-if="total > 0" class="sub-row">
                <p>{{ translatedText('handling_fee') }}:</p>
                <p>$ {{ comisiones }}</p>
              </div>
              <div class="sub-row">
                <p>{{ translatedText('total') }}:</p>
                <p>$ {{ total }}</p>
              </div>
            </div>
            <div v-if="event.asientos == 1 && totalBoletosConAsientos > 0" class="select-seats">
              <div class="separate"></div>
              <b-button @click="(seatsnModalSelect = true), getBoletosAsignarAsientos()" type="is-info" rounded expanded>{{
                translatedText('select_seats')
              }}</b-button>
            </div>
            <div class="separate"></div>
            <div v-if="parseFloat(total) > 0" class="payment-options">
              <b-field>
                <b-checkbox v-model="isPayingWithPoints" @input="updatePaymentType('points')">{{ translatedText('pay_with_points') }}</b-checkbox>
              </b-field>
              <b-field v-if="oxxoAvailable">
                <b-checkbox v-model="isPayingWithOxxo" @input="updatePaymentType('oxxo')">{{ translatedText('pay_with_oxxo') }}</b-checkbox>
              </b-field>
            </div>
            <div class="separate"></div>
            <div class="payment-card">
              <PaymentForm
                :payment-total="parseFloat(total)"
                :payment-type="paymentType"
                :loading-btn="loadingFormBtn"
                @tipo-pago-seleccionado="completarPago"
              />
            </div>
            <p class="terms-label">
              Al comprar tus boletos aceptas los
              <router-link to="/terminos-y-condiciones">Términos y Condiciones </router-link> y el
              <router-link to="/aviso-de-privacidad">Aviso de Privacidad</router-link>
            </p>
          </div>
        </div>
        <div v-else-if="event.estatus == 1">
          <div class="external-dates">
            <div v-for="fecha in event.fechas" :key="fecha.id" class="modal-dates">
              <h4 class="has-text-centered">
                <strong>{{ formatDate(fecha.fecha) }}</strong>
              </h4>
              <div class="buttons has-text-centered">
                <b-button v-for="funcion in fecha.funciones" :key="funcion.id" type="is-info" outlined disabled> {{ funcion.hora }} h </b-button>
              </div>
            </div>
            <div v-if="event.filtro_espectador" class="external-msg external-msg-loyalty">
              <img src="@/assets/images/star.png" alt="Espectador Frecuente" />
              <p class="has-text-centered">
                <strong>{{ translatedText('frequent_viewer_membership_message') }}</strong>
              </p>
            </div>
            <div v-if="event.boletos_externos.length > 0" class="external-tickets mt-5">
              <h4 class="has-text-centered">
                <strong>{{ translatedText('tickets') }}</strong>
              </h4>
              <div class="buttons has-text-centered">
                <b-button v-for="boleto in event.boletos_externos" :key="boleto.id" disabled>
                  {{ boleto.nombre }} &nbsp; ${{ boleto.precio }}</b-button
                >
              </div>
            </div>
            <div v-if="event.url == ''" class="external-msg">
              <p class="has-text-centered">{{ translatedText('tickets_not_available_online') }}</p>
              <p class="has-text-centered">{{ translatedText('tickets_available_at_box_office') }}</p>
            </div>
            <div v-else class="external-msg">
              <p class="has-text-centered">{{ translatedText('event_not_sold_platform_message') }}</p>
              <b-button tag="a" :href="event.url" target="_blank" :label="translatedText('tickets')" type="is-info" rounded expanded />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import PaymentForm from './form-stripe.vue';
import ImageZoom from '../../../components/Common/imagezoom.vue';
import Swal from 'sweetalert2';
import { getLanguage } from '@/locales/lang';

export default {
  name: 'EventDetails',
  components: {
    PaymentForm,
    ImageZoom,
  },
  data() {
    return {
      loadingFormBtn: false,
      functionModalSelect: false,
      seatsnModalSelect: false,
      comision_stripe: 0.09,
      comision_vat: 3.0,
      currentStep: 1,
      orden: {
        total: null,
        payment_token: null,
        payment_id: null,
        comision: null,
        descuento: null,
        tipo: null,
        tipo_pago: null,
        cupones_id: null,
        origen: null,
        evento_id: null,
        evento: null,
        cp: null,
        nombre_tarjeta: null,
        client_secret: null,
        voucher_url: null,
        expires_after: null,
        detalles: [
          {
            cantidad: null,
            total: null,
            boletos_id: null,
            boleto: null,
            fila: null,
            columna: null,
            precio: null,
            estatus: null,
            asientos_id: null,
          },
        ],
      },
      fecha_seleccionada_id: '',
      funcion_seleccionada_id: '',
      hora_label: '',
      fecha_label: '',
      fecha_label_completa: '',
      boletos_disponibles_funcion: [],
      boletos_seleccionados: [],
      cuponInput: '',
      isPayingWithPoints: false,
      isPayingWithOxxo: false,
      paymentType: 'card',
      titleCard: '',
      asientos_seleccionados: [],
      boletos_por_asignar_asientos: [],
      ticketModal: false,
      ticketViewModal: null,
      fechasEvent: [],
    };
  },
  computed: {
    ...mapState({
      fechasEventArray: (state) => state.event.event.fechas,
      event: (state) => state.event.event,
      cupon: (state) => state.event.cupon,
      usuario: (state) => state.auth.usuario,
      menuOpen: (state) => state.ui.menuOpen,
    }),
    ...mapGetters('payment', ['stripeInstance']),
    isEspectador() {
      if (this.usuario && this.usuario.espectador == 1) {
        return true;
      }

      return false;
    },
    filteredAsientos() {
      return (asientos) => {
        return asientos.filter((asiento) => asiento.bloqueado == 0);
      };
    },
    oxxoAvailable() {
      let aforo = this.event.aforo;

      if (this.event.virtual == '1') {
        aforo = 99999;
      }

      const limite = aforo * 0.6;
      let total_boletos_vendidos = 0;

      for (const boleto of this.boletos_disponibles_funcion) {
        if (boleto.reservar_aforo == 1) {
          total_boletos_vendidos += boleto.cantidad;
        } else {
          total_boletos_vendidos += boleto.vendidos;
        }
      }

      if (total_boletos_vendidos >= limite) {
        return false;
      }

      moment.locale('es');
      // Obtener los valores de fecha_seleccionada_id y funcion_seleccionada_id
      const idFecha = this.fecha_seleccionada_id;

      // Buscar la fecha por su id
      const fechaSeleccionada = this.fechasEvent.find((fecha) => fecha.id === idFecha);

      if (!fechaSeleccionada) {
        return false;
      } else {
        const fechaActual = moment();

        const fechaSeleccionadaMoment = moment(fechaSeleccionada.fecha);

        const diferenciaDias = fechaSeleccionadaMoment.diff(fechaActual, 'days');

        return diferenciaDias > 4;
      }
    },
    subtotal() {
      let subtotal = this.boletos_seleccionados.reduce((acc, boleto) => acc + boleto.cantidad * boleto.precio, 0);
      subtotal = Math.max(0, subtotal);
      return subtotal.toFixed(2); // Devuelve una cadena con el subtotal redondeado a dos decimales
    },
    comisiones() {
      let comisiones = 0;

      if (this.paymentType == 'points') {
        comisiones = 0;
      } else {
        let subtotal = parseFloat(this.subtotal);
        let descuentos = parseFloat(this.descuento);

        if (subtotal > 0 && this.comision_stripe > 0 && this.comision_vat > 0) {
          comisiones = (subtotal - descuentos) * this.comision_stripe + this.comision_vat;
        }
      }

      return comisiones.toFixed(2);
    },
    total() {
      // Obtener el subtotal sin descuentos
      let subtotal = parseFloat(this.subtotal);
      let comisiones = parseFloat(this.comisiones);
      let descuento = parseFloat(this.descuento);

      // Aplicar descuentos si existen
      subtotal -= descuento;

      if (subtotal <= 0) {
        return 0.0;
      }

      // Calcular las comisiones
      if (subtotal > 0 && comisiones > 0) {
        subtotal = subtotal + comisiones;
      }

      // Calcular el total final
      const totalFinal = subtotal;

      // Convertir totalFinal a un número antes de llamar a toFixed()
      const totalFinalNumber = parseFloat(totalFinal);

      // Redondear el total a dos decimales
      return totalFinalNumber.toFixed(2);
    },
    descuento() {
      // Verificar si hay un cupón activo
      if (this.cupon && this.cupon.activar == '1') {
        if (this.cupon.tipo == '0') {
          // Si el tipo de cupón es neto (tipo 0)
          if (this.cupon.por_boleto == 1) {
            const cantidad_boletos = this.boletos_seleccionados.reduce((total, boleto) => total + boleto.cantidad, 0);
            return parseFloat(this.cupon.descuento) * cantidad_boletos;
          } else {
            // Calcular el descuento neto
            return parseFloat(this.cupon.descuento);
          }
        } else if (this.cupon.tipo == '1') {
          // Si el tipo de cupón es porcentaje (tipo 1)
          return (parseFloat(this.subtotal) * parseFloat(this.cupon.descuento)) / 100;
        }
      }
      // Si no hay cupón activo o no es aplicable, el descuento es cero
      return 0;
    },
    totalBoletosConAsientos() {
      return this.boletos_seleccionados.reduce((total, boleto) => {
        // Verifica si boleto.tiene_asientos es verdadero antes de sumar la cantidad
        if (boleto.tiene_asientos) {
          return total + boleto.cantidad;
        } else {
          return total;
        }
      }, 0);
    },
    showFormPayment() {
      if (this.event.virtual == 1 && this.event.virtual_opciones == 'FECHA' && this.fechasEvent.length > 0) {
        return true;
      }

      if (this.event.virtual == 1 && this.event.virtual_opciones == 'RANGO' && this.fechasEvent.length > 0) {
        return true;
      }

      // Verifica si el estatus es 0 y si fechasEvent tiene longitud mayor que 0
      if (this.event.estatus == 0 && this.fechasEvent.length > 0) {
        return true;
      }
      // Verifica si el estatus es 1 y si fechasEventArray tiene longitud mayor que 0
      else if (this.event.estatus == 1 && this.fechasEventArray.length > 0) {
        return true;
      }
      // Si no se cumple ninguna de las condiciones anteriores, no se muestra el HTML
      return false;
    },
  },
  created() {
    if (this.event.estatus == 1) {
      this.titleCard = this.translatedText('functions_title');
    } else {
      this.titleCard = this.translatedText('buy_tickets_title');
    }

    this.$store.commit('event/SET_DATA', { key: 'cupon', data: false });
    this.filterDates();
    this.seleccionarPrimeraFechaYHora();
  },
  methods: {
    ...mapActions({
      fetchCupon: 'event/fetchCupon',
      submitOrder: 'event/submitOrder',
      fetchEvent: 'event/fetchEvent',
      setPLaces: 'event/setPLaces',
      confirmOxxoPaymentWithIntent: 'payment/confirmOxxoPaymentWithIntent',
      initializeStripe: 'payment/initializeStripe',
    }),
    ...mapMutations({
      setDataUi: 'ui/SET_DATA',
    }),
    nextStep() {
      if (!this.usuario || Object.keys(this.usuario).length === 0) {
        Swal.fire({
          icon: 'info',
          title: this.translatedText('login_required_title'),
          showConfirmButton: true, // Mostrar botón de confirmación
          confirmButtonText: this.translatedText('confirm_button_text'), // Texto del botón de confirmación
          confirmButtonColor: '#268af7', // Color del botón de confirmación
          allowOutsideClick: false, // Evitar que se cierre haciendo clic afuera
          allowEscapeKey: false, // Evitar que se cierre al presionar la tecla de escape
        }).then((result) => {
          if (result.isConfirmed) {
            this.setDataUi({ key: 'menuOpen', data: !this.menuOpen });
          }
        });

        return;
      }
      // Verificar si el usuario está intentando avanzar del currentStep 1 al currentStep 2
      if (this.currentStep === 1) {
        // Mostrar el mensaje de confirmación
        Swal.fire({
          icon: 'info',
          title: this.translatedText('date_time_verification_title'),
          showCancelButton: true,
          confirmButtonText: this.translatedText('confirm_button_text'),
          cancelButtonText: this.translatedText('cancel'),
          confirmButtonColor: '#268af7',
          cancelButtonColor: '#d33',
        }).then((result) => {
          if (result.isConfirmed) {
            // Si el usuario acepta, permitir avanzar al siguiente paso
            this.currentStep++;
          } else {
            // Si el usuario cancela, no permitir avanzar al siguiente paso
            return;
          }
        });
      } else {
        // Si no está intentando avanzar del currentStep 1 al currentStep 2, permitir avanzar al siguiente paso sin mostrar el mensaje
        if (this.currentStep < 3) {
          this.currentStep++;
        }
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    formatDate(date) {
      const language = getLanguage();
      moment.locale(language);
      let fechaFormateada = moment(date).format('dddd DD MMMM YYYY');
      fechaFormateada = `${fechaFormateada.charAt(0).toUpperCase() + fechaFormateada.slice(1)}`;
      return fechaFormateada;
    },
    filterDates() {
      // Obtener la fecha actual
      const currentDate = new Date();

      // Filtrar las fechas
      const filteredDates = [...this.fechasEventArray].filter((fecha) => {
        // Filtrar las funciones de la fecha
        fecha.funciones = fecha.funciones.filter((funcion) => {
          const functionDate = new Date(fecha.fecha + ' ' + funcion.hora);
          // Calcular la diferencia en milisegundos entre la fecha actual y la hora de la función
          const difference = currentDate - functionDate;
          // Retornar true si la diferencia es menor a 30 minutos, lo que indica que la función aún no ha pasado
          return difference < 30 * 60 * 1000;
        });

        // Retornar true si hay al menos una función en la fecha después del filtrado
        return fecha.funciones.length > 0;
      });

      // Actualizar el estado con las fechas filtradas
      this.fechasEvent = filteredDates;
    },
    async seleccionarPrimeraFechaYHora() {
      if (this.fechasEvent && this.fechasEvent.length > 0) {
        this.fecha_seleccionada_id = this.fechasEvent[0].id;
        this.funcion_seleccionada_id = this.fechasEvent[0].funciones[0].id;
        await this.buscarFechaYFuncionPorId();
      }
    },
    seleccionarFuncion(fechaId, funcionId) {
      this.fecha_seleccionada_id = fechaId;
      this.funcion_seleccionada_id = funcionId;
      this.buscarFechaYFuncionPorId();
      this.boletos_seleccionados = [];
      this.$store.commit('event/SET_DATA', { key: 'cupon', data: false });
    },
    formatearFechaRango(date) {
      const language = getLanguage();
      moment.locale(language);
      let fecha = moment(date).format('dddd DD MMMM YYYY / HH:mm');
      fecha = `${fecha.charAt(0).toUpperCase() + fecha.slice(1)}` + 'h';

      return fecha;
    },
    async buscarFechaYFuncionPorId() {
      moment.locale('es');
      // Obtener los valores de fecha_seleccionada_id y funcion_seleccionada_id
      const idFecha = this.fecha_seleccionada_id;
      const idFuncion = this.funcion_seleccionada_id;

      // Buscar la fecha por su id
      const fechaSeleccionada = this.fechasEvent.find((fecha) => fecha.id === idFecha);
      if (!fechaSeleccionada) return; // Si no se encuentra la fecha, salir del método

      // Formatear la fecha
      const fechaFormateada = this.formatDate(fechaSeleccionada.fecha);

      // Buscar la función por su id dentro de la fecha seleccionada
      const funcionSeleccionada = fechaSeleccionada.funciones.find((funcion) => funcion.id === idFuncion);
      if (!funcionSeleccionada) return; // Si no se encuentra la función, salir del método

      // Asignar los valores a fecha_label_completa
      this.fecha_label_completa = `${fechaFormateada} / ${funcionSeleccionada.hora} h`;

      this.fecha_label = fechaFormateada;
      this.hora_label = `${funcionSeleccionada.hora} h`;

      // Obtener los boletos disponibles para la función seleccionada
      await this.obtenerBoletosDisponiblesParaFuncion();
    },
    async obtenerBoletosDisponiblesParaFuncion() {
      this.boletos_disponibles_funcion = [];
      // Verificar si la función seleccionada está definida
      if (!this.funcion_seleccionada_id) {
        console.error('No se proporcionó una función válida.');
        return;
      }

      // Buscar la función seleccionada en las fechas del evento
      const fechaSeleccionada = this.fechasEvent.find((fecha) => fecha.funciones.some((funcion) => funcion.id === this.funcion_seleccionada_id));

      // Verificar si se encontró la fecha con la función seleccionada
      if (!fechaSeleccionada) {
        console.error('No se encontró la función seleccionada en ninguna fecha.');
        return;
      }

      // Encontrar la función seleccionada dentro de la fecha
      const funcionSeleccionada = fechaSeleccionada.funciones.find((funcion) => funcion.id === this.funcion_seleccionada_id);

      // Verificar si se encontró la función seleccionada
      if (!funcionSeleccionada) {
        console.error('No se encontró la función seleccionada en la fecha especificada.');
        return;
      }

      // Asignar los boletos disponibles para la función seleccionada a boletos_disponibles_funcion
      this.boletos_disponibles_funcion = funcionSeleccionada.boletos.map((boleto) => ({
        id: boleto.id,
        nombre: boleto.nombre.charAt(0).toUpperCase() + boleto.nombre.slice(1),
        precio: parseFloat(boleto.precio),
        vendidos: parseFloat(boleto.vendidos),
        cantidad: parseFloat(boleto.cantidad),
        reservar_aforo: boleto.reservar_aforo,
        descripcion: boleto.descripcion,
        asientos: boleto.asientos,
        tiene_asientos: boleto.tiene_asientos,
        espectador: boleto.espectador,
      }));
    },
    validarlimiteDeBoletos() {
      const limite = this.event.limite_boletos;
      const boletos = this.boletos_seleccionados;

      if (limite <= 0) {
        return true;
      }

      const totalBoletos = boletos.reduce((total, boleto) => total + boleto.cantidad, 0);

      if (totalBoletos > limite) {
        return false;
      }

      return true;
    },
    async handleCantidadBoletosChange(boleto, cantidad) {
      // Buscar si ya existe un boleto con el mismo ID en el arreglo de boletos seleccionados
      const index = this.boletos_seleccionados.findIndex((item) => item.boletos_id === boleto.id);

      if (cantidad > 0) {
        // Si la cantidad es mayor que 0, agregar o actualizar el boleto en el arreglo de boletos seleccionados
        if (index !== -1) {
          // Si ya existe un boleto con el mismo ID, actualizar su cantidad
          this.boletos_seleccionados[index].cantidad = cantidad;
        } else {
          // Si no existe, agregar un nuevo elemento al arreglo
          const boletoSeleccionado = {
            cantidad: cantidad,
            boletos_id: boleto.id,
            boleto: boleto.nombre,
            precio: boleto.precio,
            espectador: boleto.espectador,
            estatus: 0,
            asientos: null,
            tiene_asientos: boleto.tiene_asientos,
          };
          this.boletos_seleccionados.push(boletoSeleccionado);
        }
      } else {
        // Si la cantidad es 0 o menor, eliminar el boleto del arreglo de boletos seleccionados
        if (index !== -1) {
          this.boletos_seleccionados.splice(index, 1);
        }
      }

      const limite_boletos = this.validarlimiteDeBoletos();

      if (!limite_boletos) {
        Swal.fire({
          icon: 'info',
          title: this.translatedText('oxxo_ticket_limit') + ' ' + this.event.limite_boletos + ' ' + this.translatedText('oxxo_ticket_limit_2'),
          confirmButtonColor: '#268af7',
        });

        await this.clearForm();
        return;
      }

      const boletosEspectador = this.boletos_seleccionados.find((boleto) => boleto.espectador == 1);

      if (boletosEspectador && this.usuario.espectador == 0) {
        Swal.fire({
          icon: 'info',
          title: 'Para comprar boletos con esta tarifa preferencial, debes adquirir primero tu membresía Espectador Frecuente.',
          confirmButtonColor: '#268af7',
        });

        await this.clearForm();
        return;
      }
    },
    getCantidadBoletosSeleccionados(boletoId) {
      const boletosSeleccionados = this.boletos_seleccionados.filter((boleto) => boleto.boletos_id === boletoId);

      if (boletosSeleccionados.length > 0) {
        return boletosSeleccionados.reduce((total, boleto) => total + boleto.cantidad, 0);
      } else {
        return 0;
      }
    },
    async applyCupon() {
      // Verificar si cuponInput no está vacío
      if (this.cuponInput.trim() !== '') {
        // Mostrar mensaje de carga
        Swal.fire({
          icon: 'info',
          title: this.translatedText('coupon_validation_title'),
          allowOutsideClick: false, // Evita que el usuario cierre el mensaje
          showConfirmButton: false, // Oculta el botón de confirmación
          onBeforeOpen: () => {
            Swal.showLoading(); // Muestra el ícono de carga
          },
        });

        // Si no está vacío, llamar al método loadCupon
        await this.loadCupon();
      } else {
        // Si está vacío, mostrar un mensaje de error
        Swal.fire({
          icon: 'error',
          title: this.translatedText('empty_coupon_field_title'),
          text: this.translatedText('invalid_coupon_text'),
          confirmButtonColor: '#268af7',
        });
      }
    },
    async loadCupon() {
      // Llama a tu método fetchCupon y pasa el valor del input como parte de los parámetros

      const payload_cupon = await this.fetchCupon({
        nombre: this.cuponInput, // Usa el valor de cuponInput como nombre del cupón
        funciones_id: this.funcion_seleccionada_id,
      });

      if (payload_cupon.status) {
        Swal.fire({
          icon: 'success',
          title: this.translatedText(payload_cupon.code),
          confirmButtonColor: '#268af7',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: this.translatedText(payload_cupon.code),
          confirmButtonColor: '#268af7',
        });
      }
    },
    async completarPago(data) {
      this.loadingFormBtn = true;

      if (!this.usuario || Object.keys(this.usuario).length === 0) {
        Swal.fire({
          icon: 'info',
          title: this.translatedText('login_required_title'),
          showConfirmButton: true, // Mostrar botón de confirmación
          confirmButtonText: this.translatedText('confirm_button_text'), // Texto del botón de confirmación
          confirmButtonColor: '#268af7', // Color del botón de confirmación
          allowOutsideClick: false, // Evitar que se cierre haciendo clic afuera
          allowEscapeKey: false, // Evitar que se cierre al presionar la tecla de escape
        }).then((result) => {
          if (result.isConfirmed) {
            this.setDataUi({ key: 'menuOpen', data: !this.menuOpen });
          }
        });

        this.loadingFormBtn = false;
        return;
      }

      let comisiones = this.total < 1 ? 0 : this.comisiones;

      let charge_info = {
        type: 'events',
        boletos: this.boletos_seleccionados,
        total: this.total,
        subtotal: this.subtotal,
        comisiones: comisiones,
        descuento: this.descuento,
        cupon: this.cupon,
        evento_id: this.event.id,
        fecha_id: this.fecha_seleccionada_id,
        funcion_id: this.funcion_seleccionada_id,
        evento: this.event.nombre,
        paymentType: data.paymentType,
        asientos: this.event.asientos,
      };

      if (data.paymentType === 'card') {
        charge_info.card_token = data.token.id;
        charge_info.cp = data.token.card.address_zip;
        charge_info.nombre_tarjeta = data.token.card.name;
      }

      await this.generateOrder(charge_info, data);
    },
    async generateOrder(charge_info) {
      try {
        Swal.fire({
          icon: 'info',
          title: this.translatedText('loading'),
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        });

        if (this.event.asientos == 1 && this.asientos_seleccionados.length <= 0 && this.totalBoletosConAsientos > 0) {
          Swal.close();

          Swal.fire({
            icon: 'info',
            title: this.translatedText('seat_selection_title'),
            showConfirmButton: true,
            confirmButtonText: this.translatedText('confirm_button_text'),
            confirmButtonColor: '#268af7',
          });

          this.loadingFormBtn = false;
          return;
        }

        const orderConfirmation = await this.submitOrder(charge_info); // Espera a que se resuelva la promesa

        Swal.close();

        // Aquí puedes acceder a los datos devueltos por la promesa
        if (orderConfirmation && orderConfirmation.status) {
          if (charge_info.paymentType != 'oxxo') {
            Swal.fire({
              icon: 'success',
              title: this.translatedText('order_success_title'),
              html: '<div>' + this.translatedText('order_success_text') + '<br>' + this.translatedText('order_success_text_2') + '</div>',
              showConfirmButton: false,
              footer: `
                <a href="/usuario/mis-datos" class="button is-info is-rounded">
                  Ver mis boletos
                </a>
              `,
            });
          }
        } else {
          let text_error = this.translatedText(orderConfirmation.code);

          if (orderConfirmation.code == "pv406") {
            text_error = this.translatedText(orderConfirmation.code) + orderConfirmation.data + this.translatedText('pv406_2');
          }

          if (orderConfirmation.code == "pv409") {
            text_error = this.translatedText(orderConfirmation.code) + orderConfirmation.data + this.translatedText('pv409_2');
          }

          Swal.fire({
            icon: 'error',
            title: this.translatedText('order_error_title'),
            text: text_error,
            confirmButtonColor: '#268af7',
          });

          this.loadingFormBtn = false;
          return;
        }

        if (charge_info.paymentType == 'oxxo') {
          const client_secret = orderConfirmation.payload.order.client_secret;

          const billing_details = {
            name: this.usuario.nombre + ' ' + this.usuario.apellido,
            email: this.usuario.email,
          };

          try {
            await this.confirmOxxoPaymentWithIntent({
              client_secret: client_secret,
              billing_details: billing_details,
            });

            Swal.fire({
              icon: 'success',
              title: this.translatedText('oxxo_payment_success_title'),
              text: this.translatedText('oxxo_payment_success_text'),
              confirmButtonColor: '#268af7',
            });
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: this.translatedText('oxxo_payment_error_title'),
              text: error.message,
              confirmButtonColor: '#268af7', // Color personalizado del botón de confirmación
            });
          }
        } else {
          //this.ticketModal = true;
          //this.ticketViewModal = orderConfirmation.payload.order;
        }

        this.loadingFormBtn = false;
        await this.clearForm();
      } catch (error) {
        this.loadingFormBtn = false;
        console.error('Error al generar la orden:', error);
        // Manejar el error si la promesa se rechaza
      }
    },
    async clearForm() {
      this.currentStep = 1;

      this.boletos_seleccionados = [];
      this.asientos_seleccionados = [];
      this.boletos_por_asignar_asientos = [];

      await this.loadEvent();
      this.filterDates();

      await this.seleccionarPrimeraFechaYHora();
    },
    formatCosto(costo) {
      const costoNumero = costo ? parseFloat(costo) : 0;

      if (!isNaN(costoNumero) && typeof costoNumero === 'number') {
        return '$ ' + costoNumero.toFixed(2);
      } else {
        return '$ 0.00';
      }
    },
    updatePaymentType(selectedOption) {
      // Si la opción seleccionada es igual al método de pago actual
      if (selectedOption === this.paymentType) {
        // Desmarca las opciones de oxxo y puntos
        this.isPayingWithOxxo = false;
        this.isPayingWithPoints = false;

        // Establece el método de pago como 'card'
        this.paymentType = 'card';

        // Si el subtotal es cero, establece el método de pago como 'free'
        if (this.subtotal === 0) {
          this.paymentType = 'free';
        }

        return;
      }

      // Si se selecciona la opción 'points'
      if (selectedOption === 'points') {
        // Desmarca la opción de oxxo y establece el método de pago como 'points'
        this.isPayingWithOxxo = false;
        this.paymentType = 'points';
      } else if (selectedOption === 'oxxo') {
        // Si se selecciona la opción 'oxxo'
        // Desmarca la opción de puntos
        this.isPayingWithPoints = false;

        // Muestra un mensaje de información
        Swal.fire({
          icon: 'info',
          title: this.translatedText('payment_time_limit_title'),
          showCancelButton: true,
          confirmButtonText: this.translatedText('confirm_button_text'),
          cancelButtonText: this.translatedText('cancel'),
          confirmButtonColor: '#268af7',
          cancelButtonColor: '#d33',
        }).then((result) => {
          // Si se confirma la acción
          if (result.isConfirmed) {
            // Establece el método de pago como 'oxxo'
            this.paymentType = 'oxxo';
          } else {
            // Si el usuario cancela, desmarca la opción de oxxo y establece el método de pago como 'card'
            this.isPayingWithOxxo = false;
            this.paymentType = 'card';
          }
        });
      }

      // Si el subtotal es cero, establece el método de pago como 'free'
      if (this.subtotal === 0) {
        this.paymentType = 'free';
      }
    },
    async guardarAsientos() {
      Swal.fire({
        icon: 'info',
        title: this.translatedText('loading'),
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
      });
      // Variable para almacenar los asientos seleccionados
      const asientosSeleccionados = [];
      this.asientos_seleccionados = [];

      // Buscar todos los elementos select fuera del bucle
      const selects = this.boletos_por_asignar_asientos.map((boleto, index) => {
        const boletoId = boleto.boletos_id;
        return document.querySelector(`#select-asiento-${boletoId}-${index}`);
      });

      // Recorrer los boletos por asignar asientos
      this.boletos_por_asignar_asientos.forEach((boleto, index) => {
        // Obtener el objeto de asiento correspondiente al índice seleccionado
        const asientoSeleccionado = boleto.asientos.find((asiento) => asiento.id === selects[index].value);

        // Si se seleccionó un asiento, guardarlo
        if (asientoSeleccionado) {
          // Agregar el boletoId y asientoSeleccionado al array de asientos seleccionados
          asientosSeleccionados.push({ boletoId: boleto.boletos_id, asientoSeleccionado });
          this.asientos_seleccionados.push(asientoSeleccionado);
        }
      });

      if (this.asientos_seleccionados.length != this.totalBoletosConAsientos) {
        Swal.close();

        Swal.fire({
          icon: 'info',
          title: this.translatedText('seat_selection_title'),
          showConfirmButton: true,
          confirmButtonText: this.translatedText('confirm_button_text'),
          confirmButtonColor: '#268af7',
        });

        return;
      }

      // Validar si hay elementos repetidos en this.asientos_seleccionados
      const asientosRepetidos = this.asientos_seleccionados.filter((asiento, index, self) => self.findIndex((a) => a.id === asiento.id) !== index);

      if (asientosRepetidos.length > 0) {
        // Mostrar mensaje con SweetAlert si hay elementos repetidos
        const asientosRepetidosTexto = asientosRepetidos.map((asiento) => `${asiento.fila}-${asiento.columna}`).join(', ');
        Swal.close();

        Swal.fire({
          icon: 'info',
          title: this.translatedText('duplicate_seats_title') + `${asientosRepetidosTexto}`,
          showConfirmButton: true,
          confirmButtonText: this.translatedText('confirm_button_text'),
          confirmButtonColor: '#268af7',
        });

        this.asientos_seleccionados = [];
        return; // Detener la ejecución del método si hay elementos repetidos
      }

      ///Validar con la api si los asientos estan disponibles
      const reserve = await this.reservePlaces(this.asientos_seleccionados);

      if (reserve && !reserve.status) {
        await this.loadEvent();
        await this.obtenerBoletosDisponiblesParaFuncion();
        await this.getBoletosAsignarAsientos();

        Swal.close();

        Swal.fire({
          icon: reserve.class,
          title: this.translatedText('seat_reservation_title'),
          text: this.translatedText(reserve.code),
          confirmButtonColor: '#268af7',
        });

        return;
      }

      // Agrupar los asientos seleccionados por boletoId
      const asientosPorBoleto = {};
      asientosSeleccionados.forEach(({ boletoId, asientoSeleccionado }) => {
        if (!asientosPorBoleto[boletoId]) {
          asientosPorBoleto[boletoId] = [];
        }
        asientosPorBoleto[boletoId].push(asientoSeleccionado);
      });

      // Asignar los asientos seleccionados a los boletos seleccionados
      this.boletos_seleccionados.forEach((boleto) => {
        const asientos = asientosPorBoleto[boleto.boletos_id];
        if (asientos) {
          boleto.asientos = asientos;
        }
      });

      // Hacer algo con los boletos_seleccionados actualizados

      // Cerrar el modal de selección de asientos
      Swal.close();

      Swal.fire({
        icon: reserve.class,
        title: this.translatedText('seat_reservation_title'),
        text: this.translatedText(reserve.code),
        confirmButtonColor: '#268af7',
      });

      this.seatsnModalSelect = false;
      this.boletos_por_asignar_asientos = [];
    },
    asientosTexto(asientos) {
      if (asientos && Array.isArray(asientos)) {
        // Comprueba si asientos no es null y es un array
        return '(' + asientos.map((asiento) => `${asiento.fila}-${asiento.columna}`).join(', ') + ')';
      } else {
        return ''; // Retorna una cadena vacía si asientos es null o no es un array
      }
    },
    async loadEvent() {
      await this.fetchEvent({ evento_id: this.event.id });
    },
    async reservePlaces(places) {
      return await this.setPLaces({ asientos: places });
    },
    async getBoletosAsignarAsientos() {
      this.boletos_por_asignar_asientos = [];

      const nuevosBoletos = [];

      this.boletos_seleccionados.forEach((boletoSeleccionado) => {
        const boletoDisponible = this.boletos_disponibles_funcion.find(
          (boletoDisponible) => boletoDisponible.id == boletoSeleccionado.boletos_id && boletoDisponible.tiene_asientos,
        );
        if (boletoDisponible) {
          for (let i = 0; i < boletoSeleccionado.cantidad; i++) {
            const nuevoBoleto = {
              cantidad: 1,
              boletos_id: boletoDisponible.id,
              boleto: boletoDisponible.nombre,
              precio: boletoDisponible.precio,
              estatus: boletoDisponible.estatus,
              asientos: boletoDisponible.asientos, // Aquí asignamos los asientos correspondientes al boleto disponible
            };
            nuevosBoletos.push(nuevoBoleto);
          }
        }
      });

      this.boletos_por_asignar_asientos = nuevosBoletos;

      return nuevosBoletos;
    },
  },
};
</script>