<template>
  <form @submit.prevent="login" @keyup.enter="login" class="login-form">
    <h3 class="is-size-5 has-text-weight-bold has-text-centered mb-2">{{ translatedText('login') }}</h3>
    <b-field :label="translatedText('email')" :message="emailError">
      <b-input v-model="email" type="email" :placeholder="translatedText('email')"></b-input>
    </b-field>

    <b-field :label="translatedText('password')" :message="passwordError">
      <b-input v-model="password" type="password" password-reveal :placeholder="translatedText('password')"></b-input>
    </b-field>
    <b-button @click="login" :loading="sendForm" type="is-info" expanded>{{ translatedText('login_button') }}</b-button>
  </form>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      emailError: null,
      passwordError: null,
      sendForm: false,
    };
  },
  methods: {
    ...mapActions('auth', ['loginUser']),
    async login() {
      // Validar campos antes de enviar
      if (!this.email) {
        this.emailError = this.translatedText('please_enter_email');
        return;
      }
      if (!this.password) {
        this.passwordError = this.translatedText('please_enter_password');
        return;
      }

      this.sendForm = true;

      const authData = await this.loginUser({
        email: this.email,
        tipo_sesion: 'web',
        password: this.password,
      });

      if (authData.status) {
        Swal.fire({
          icon: 'success',
          title: this.translatedText('login_success') + authData.payload.nombre,
          confirmButtonColor: '#268af7',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: this.translatedText('login_error'),
          confirmButtonColor: '#268af7',
        });
      }

      this.emailError = '';
      this.passwordError = '';

      // Guardar los datos en el almacenamiento local
      localStorage.setItem('usuario', JSON.stringify(authData.payload));
      localStorage.setItem('token', authData.payload.token);
      localStorage.setItem('token_sesion', authData.payload.token_sesion);

      this.sendForm = false;
    },
  },
};
</script>
