<template>
  <div>
    <b-loading :is-full-page="true" :active="is_loading" :can-cancel="false" :fullscreen="true">
      <template #default>
        <div class="has-text-centered">
          <h1 class="title">Cargando...</h1>
        </div>
      </template>
    </b-loading>

    <div v-if="ticketModal" class="modal profile-modal-ticket" :class="{ 'is-active': ticketModal }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">¡Disfruta la función!</p>
          <button @click="ticketModal = false" class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-centered is-multiline">
            <div class="column is-4">
              <figure class="image">
                <img :src="ticketViewModal.qr" />
              </figure>
            </div>
            <div class="column is-12 has-text-centered">
              <p class=""><strong>Evento:</strong> {{ ticketViewModal.obra.titulo }} {{ ticketViewModal.obra.subtitulo }}</p>
              <p class=""><strong>Fecha:</strong> {{ ticketViewModal.fecha }}</p>
              <p class=""><strong>Hora:</strong> {{ ticketViewModal.hora }}</p>
              <p class=""><strong>Lugar:</strong> {{ ticketViewModal.lugar }}</p>
              <p class=""><strong>Costo:</strong> {{ formatCosto(ticketViewModal.total) }}</p>
              <br />
            </div>
            <div class="column is-12 has-text-centered">
              <p style="margin-bottom: 0"><strong>Boletos:</strong></p>
              <div v-for="entrada in ticketViewModal.entradas" :key="entrada.descripcion">
                <p class="clamp-1" style="margin: 0">
                  {{ entrada.descripcion + ' (' + entrada.cantidad + ')' }}
                </p>
                <div v-if="entrada.asientos">
                  <p class="clamp-1" style="margin: 0" v-for="asiento in entrada.asientos" :key="asiento.descripcion">
                    {{ asiento.fila + '-' + asiento.numero }}
                  </p>
                </div>
              </div>
            </div>
            <br />
            <div class="column is-12">
              <p class="has-text-centered">
                Código de confirmación: <br />
                <strong>{{ ticketViewModal.codigo }}</strong>
              </p>
              <br />
              <p class="has-text-centered">
                <strong>Presenta este boleto en la entrada del teatro.</strong>
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>

    <section v-if="userData && !is_loading" class="profile-page">
      <div class="profile-detail-header">
        <div class="container">
          <div class="columns is-vcentered is-centered is-multiline">
            <div class="column is-4">
              <div class="profile-img">
                <b-image :src="userData.portada" rounded></b-image>
              </div>
            </div>
            <div class="column is-4">
              <div class="profile-data">
                <h2 class="title">{{ userData.nombre + ' ' + userData.apellido }}</h2>
                <h3 class="subtitle">{{ userData.email }}</h3>
                <div v-if="userData.tef > 0"  class="profile-ef">
                  <p class="subtitle">No. de espectador</p>
                  <p class="subtitle num-espectador">{{ userData.tef }}</p>
                  <br />
                  <p class="subtitle">Espectador Frecuente desde:</p>
                  <p class="subtitle">{{ userData.fecha_espectador }}</p>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div v-if="userData.tef > 0" class="profile-data-tef">
                <h2 class="title">{{ userData.puntos }}</h2>
                <h3 class="subtitle">Puntos</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="tickets.length > 0" class="profile-tickets">
        <div class="container">
          <h2 class="title">Mis boletos</h2>
          <div class="columns is-multiline">
            <div v-for="ticket in tickets" :key="ticket.orden" class="column is-3">
              <div class="card">
                <div class="card-image">
                  <figure class="image is-4by3">
                    <img :src="ticket.obra.portada" alt="Placeholder image" />
                    <div class="image-title clamp-1">{{ ticket.obra.titulo }}</div>
                  </figure>
                </div>
                <div class="card-content">
                  <div class="content">
                    <p><strong>Fecha:</strong> {{ ticket.fecha }}</p>
                    <p><strong>Hora:</strong> {{ ticket.hora }}</p>
                    <p class="clamp-1"><strong>Lugar:</strong> {{ ticket.lugar }}</p>
                    <p style="margin-bottom: 0"><strong>Boletos:</strong></p>
                    <p class="clamp-1" style="margin: 0">
                      {{ ticket.entradas[0].descripcion + ' (' + ticket.entradas[0].cantidad + ')' }}
                    </p>
                  </div>
                  <b-button @click="viewTicket(ticket)" type="is-info" expanded>Ver Boleto</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="totalPages > currentPage" class="columns is-mobile is-centered load-more">
        <div class="column is-12 is-half has-text-centered">
          <b-button @click="loadMoreTickets" class="is-medium vat-btn" type="is-info" icon-right="menu-down"> Más boletos </b-button>
        </div>
      </div>
      <div class="profile-edit">
        <div class="container">
          <form class="personal-form">
            <h2 class="title">Editar perfil</h2>
            <div class="columns is-multiline">
              <div class="column is-3">
                <b-field label="Nombre" :message="nameError">
                  <b-input v-model="editedUserData.nombre" type="text" placeholder="Nombre"></b-input>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Apellido" :message="lastNameError">
                  <b-input v-model="editedUserData.apellido" type="text" placeholder="Apellido"></b-input>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Correo electrónico" :message="emailError">
                  <b-input v-model="editedUserData.email" type="email" disabled placeholder="Correo electrónico"></b-input>
                </b-field>
              </div>
              <div class="column is-3">
                <b-button @click="saveChanges" type="is-info" expanded>Guardar</b-button>
              </div>
            </div>
          </form>
          <form @submit.prevent="changePassword" class="password-form">
            <h2 class="title">Cambiar contraseña</h2>
            <div class="columns is-multiline">
              <div class="column is-3">
                <b-field label="Contraseña actual" :message="passwordError">
                  <b-input v-model="passwordData.currentPassword" type="password" password-reveal placeholder="Contraseña actual"></b-input>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Nueva contraseña" :message="newPasswordError">
                  <b-input v-model="passwordData.newPassword" type="password" password-reveal placeholder="Nueva contraseña"></b-input>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field label="Confirmar contraseña" :message="confirmNewPasswordError">
                  <b-input v-model="passwordData.confirmNewPassword" type="password" password-reveal placeholder="Confirmar contraseña"></b-input>
                </b-field>
              </div>
              <div class="column is-3">
                <b-button @click="changePassword" type="is-info" expanded>Guardar</b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Swal from 'sweetalert2';

export default {
  name: 'Profile',
  data() {
    return {
      is_loading: false,
      editedUserData: {
        nombre: null,
        apellido: null,
        email: null,
      },
      nameError: null,
      lastNameError: null,
      emailError: null,
      passwordData: {
        currentPassword: null,
        newPassword: null,
        confirmNewPassword: null,
      },
      passwordError: null,
      newPasswordError: null,
      confirmNewPasswordError: null,
      page: 1,
      size: 4,
      ticketModal: false,
      ticketViewModal: null,
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.auth.usuario,
      tickets: (state) => state.auth.list,
      options: (state) => state.auth.options,
    }),
    totalPages() {
      return this.options.totalPages || 1;
    },
    currentPage() {
      return parseInt(this.$route.query.page) || 1;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadAlldata();
    });
  },
  methods: {
    ...mapActions({
      fetchUserData: 'auth/fetchUserData',
      fetchTicketsList: 'auth/fetchTicketsList',
      edithUserData: 'auth/edithUserData',
    }),
    ...mapMutations({
      setData: 'auth/SET_DATA',
    }),
    async loadAlldata() {
      window.scrollTo(0, 0);
      this.setData({ key: 'list', data: [] });
      await this.loadUserData();
      this.loadFormData();
      await this.loadTickets();
    },
    loadFormData() {
      this.editedUserData = {
        nombre: this.userData.nombre || null,
        apellido: this.userData.apellido || null,
        email: this.userData.email || null,
      };
    },
    async loadUserData() {
      await this.fetchUserData();
      localStorage.setItem('usuario', JSON.stringify(this.userData));
    },
    loadTickets() {
      this.fetchTicketsList({ page: this.page, size: this.size });
    },
    loadMoreTickets() {
      this.page++;
      this.fetchTicketsList({ page: this.page, size: this.size });
    },
    async saveChanges() {
      // Validar los campos antes de guardar los cambios
      this.clearErrors(); // Limpiar los mensajes de error anteriores

      // Validar el nombre
      if (!this.editedUserData.nombre) {
        this.nameError = 'Por favor, ingresa tu nombre.';
        return;
      }

      // Validar el apellido
      if (!this.editedUserData.apellido) {
        this.lastNameError = 'Por favor, ingresa tu apellido.';
        return;
      }

      // Si todos los campos son válidos, guardar los cambios
      Swal.fire({
        icon: 'info',
        title: 'Cargando...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
      });

      try {
        const response = await this.edithUserData({
          nombre: this.editedUserData.nombre,
          apellido: this.editedUserData.apellido,
        });

        Swal.close();

        Swal.fire({
          icon: response.class,
          title: 'Perfil',
          text: response.msg,
          confirmButtonColor: '#268af7',
        });

        if (response.status) {
          this.loadFormData();
        }
      } catch (error) {
        console.error('Error al guardar los cambios:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al guardar los cambios. Por favor, inténtelo de nuevo más tarde.',
          confirmButtonColor: '#268af7',
        });
      }
    },
    async changePassword() {
      // Limpiar los mensajes de error anteriores
      this.clearPasswordErrors();

      // Validar que se hayan ingresado todas las contraseñas
      if (!this.passwordData.currentPassword) {
        this.passwordError = 'Por favor, ingresa tu contraseña actual.';
      } else if (!this.passwordData.newPassword) {
        this.newPasswordError = 'Por favor, ingresa tu nueva contraseña.';
      } else if (!this.passwordData.confirmNewPassword) {
        this.confirmNewPasswordError = 'Por favor, confirma tu nueva contraseña.';
      } else if (this.passwordData.newPassword !== this.passwordData.confirmNewPassword) {
        // Validar que la nueva contraseña y la confirmación coincidan
        this.confirmNewPasswordError = 'Las contraseñas no coinciden.';
      } else {
        // Si todos los campos son válidos, cambiar la contraseña
        Swal.fire({
          icon: 'info',
          title: 'Cargando...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        });

        const response = await this.edithUserData({
          password: this.passwordData.currentPassword,
          newPassword: this.passwordData.newPassword,
        });

        Swal.close();

        Swal.fire({
          icon: response.class,
          title: 'Contraseña',
          text: response.msg,
          confirmButtonColor: '#268af7',
        });
      }
    },
    clearPasswordErrors() {
      // Limpiar los mensajes de error de contraseña
      this.passwordError = '';
      this.newPasswordError = '';
      this.confirmNewPasswordError = '';
    },
    clearErrors() {
      // Limpiar los mensajes de error
      this.nameError = null;
      this.lastNameError = null;
    },
    viewTicket(ticket) {
      this.ticketModal = true;
      this.ticketViewModal = ticket;
    },
    formatCosto(costo) {
      const costoNumero = costo ? parseFloat(costo) : 0;

      if (!isNaN(costoNumero) && typeof costoNumero === 'number') {
        return '$ ' + costoNumero.toFixed(2);
      } else {
        return '$ 0.00';
      }
    },
  },
};
</script>
