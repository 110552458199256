<template>
  <div>
    <b-loading :is-full-page="true" :active="is_loading" :can-cancel="false" :fullscreen="true">
      <template #default>
        <div class="has-text-centered">
          <h1 class="title">{{ translatedText('loading') }}</h1>
        </div>
      </template>
    </b-loading>
    <section v-if="!is_loading && loyaltyProcess" class="loyalty-process-page">
      <div
        class="loyalty-process-header"
        v-if="loyaltyProcess && loyaltyProcess.fondo"
        :style="{ backgroundImage: 'url(' + loyaltyProcess.fondo + ')' }"
      >
        <div class="container">
          <div class="columns is-vcentered is-multiline">
            <div class="column is-8" v-html="loyaltyProcess.contenido"></div>
            <div class="column">
              <figure class="image">
                <img :src="loyaltyProcess.imagen" />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div v-if="userData.tef > 0" class="loyalty-success">
        <h2>{{ translatedText('frequent_viewer_success_title') }}</h2>
      </div>
      <div v-if="!userData.nombre" class="loyalty-success">
        <h2>{{ translatedText('login_or_register_title') }}</h2>
      </div>
      <div v-if="userData && userData.tef < 1" class="loyalty-form">
        <div class="container">
          <form @submit.prevent="saveChanges" class="personal-form">
            <h2 class="title">{{ translatedText('user_information_title') }}</h2>
            <div class="columns is-multiline">
              <div class="column is-3">
                <b-field :label="translatedText('birthdate_placeholder')" :message="birthdayError" type="is-info">
                  <b-datepicker
                    v-model="efData.birthday"
                    show-week-number
                    :placeholder="translatedText('birthdate_placeholder')"
                    icon="calendar-today"
                    icon-right-clickable
                    trap-focus
                    :max-date="calculateMaxBirthday()"
                  >
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field :label="translatedText('state')" :message="selectedStateError" type="is-info">
                  <b-select v-model="efData.selectedState" :placeholder="translatedText('state')">
                    <template v-for="state in statesCatalog">
                      <option v-if="state.estado && state.siglas !== '' && state.siglas != 'ALL'" :key="state.id" :value="state.id">
                        {{ state.estado.toUpperCase() }}
                      </option>
                    </template>
                  </b-select>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field :label="translatedText('full_address_label')" :message="fullAddressError" type="is-info">
                  <b-input v-model="efData.fullAddress" type="text" :placeholder="translatedText('full_address_label')"></b-input>
                </b-field>
              </div>
              <div class="column is-3">
                <b-field :label="translatedText('recommendation_code_label')" :message="recommendationCodeError" type="is-info">
                  <b-input v-model="efData.recommendationCode" type="text" :placeholder="translatedText('recommendation_code_label')"></b-input>
                </b-field>
                <p>{{ translatedText('no_recommendation_code_message') }}</p>
              </div>
              <div class="column is-6">
                <figure class="image" v-if="loyaltyProcess && loyaltyProcess.ejemplo">
                  <img :src="loyaltyProcess.ejemplo" />
                </figure>
                <b-field :label="translatedText('image')" :message="profileImageError" type="is-info">
                  <b-upload v-model="efData.profileImage" drag-drop>
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"> </b-icon>
                        </p>
                        <p v-if="efData.profileImage">{{ efData.profileImage.name }}</p>
                        <p v-else>{{ translatedText('drop_files_here_message') }}</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
              </div>
              <div class="loyalty-price-info column is-12">
                <p>{{ translatedText('account_cost_info') }} ${{ precioTef }}.00.</p>
                <b-field type="is-info">
                  <b-checkbox v-model="termsAndPrivacyAccepted">
                    <p class="terms-label">
                      He leído y acepto los
                      <router-link to="/terminos-y-condiciones-espectadorfrecuente">Términos y Condiciones Espectador Frecuente</router-link> y el
                      <router-link to="/aviso-de-privacidad">Aviso de Privacidad</router-link>
                    </p>
                  </b-checkbox>
                </b-field>
              </div>
            </div>
            <div v-if="termsAndPrivacyAccepted" class="payment-form-process">
              <div class="columns is-multiline">
                <div class="column is-6">
                  <div class="payment-options">
                    <b-field>
                      <b-checkbox v-model="isPayingWithOxxo" @input="updatePaymentType">Pagar en oxxo</b-checkbox>
                    </b-field>
                  </div>
                  <div class="separate"></div>
                  <div class="payment-card">
                    <PaymentForm
                      :payment-total="parseFloat(precioTef)"
                      :payment-type="paymentType"
                      :loading-btn="loadingFormBtn"
                      @tipo-pago-seleccionado="saveChanges"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PaymentForm from './form-stripe.vue';
import Swal from 'sweetalert2';

export default {
  name: 'Process',
  components: {
    PaymentForm,
  },
  data() {
    return {
      is_loading: false,
      termsAndPrivacyAccepted: false,
      isPayingWithOxxo: false,
      paymentType: 'card',
      efData: {
        birthday: null,
        selectedState: null,
        recommendationCode: null,
        fullAddress: null,
        profileImage: null,
      },
      birthdayError: null,
      selectedStateError: null,
      recommendationCodeError: null,
      fullAddressError: null,
      profileImageError: null,
      loadingFormBtn: false,
    };
  },
  computed: {
    ...mapState({
      statesCatalog: (state) => state.statesCatalog.statesCatalog,
      userData: (state) => state.auth.usuario,
      espectador: (state) => state.dashboard.espectador,
      usuario: (state) => state.auth.usuario,
    }),
    loyaltyProcess() {
      return this.espectador.find((item) => item.id == '3' || item.ubicacion == 'Tramite');
    },
    precioTef() {
      if (this.loyaltyProcess && this.loyaltyProcess.configuraciones && typeof this.loyaltyProcess.configuraciones.valor !== 'undefined') {
        return this.loyaltyProcess.configuraciones.valor;
      } else {
        return 350; // Valor predeterminado si no se encuentra configuración
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadUserData();
    });
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions({
      confirmOxxoPaymentWithIntent: 'payment/confirmOxxoPaymentWithIntent',
      submitCardOrder: 'payment/submitCardOrder',
      fetchUserData: 'auth/fetchUserData',
    }),
    async loadUserData() {
      await this.fetchUserData();
      localStorage.setItem('usuario', JSON.stringify(this.userData));
    },
    async saveChanges(data) {
      this.loadingFormBtn = true;
      // Validar que this.efData.birthday no sea null y sea una fecha válida
      if (!this.efData.birthday || isNaN(new Date(this.efData.birthday))) {
        Swal.fire({
          icon: 'error',
          title: 'Por favor, selecciona una fecha de nacimiento válida para tu cumpleaños.',
          confirmButtonColor: '#268af7',
        });
        this.loadingFormBtn = false;
        return;
      }

      // Validar que this.efData.selectedState no sea null y sea una fecha válida
      if (!this.efData.selectedState) {
        Swal.fire({
          icon: 'error',
          title: 'Por favor, selecciona una estado.',
          confirmButtonColor: '#268af7',
        });
        this.loadingFormBtn = false;
        return;
      }

      // Validar que this.efData.fullAddress no sea null y sea una fecha válida
      if (!this.efData.fullAddress) {
        Swal.fire({
          icon: 'error',
          title: 'Por favor, ingresa una dirección válida.',
          confirmButtonColor: '#268af7',
        });
        this.loadingFormBtn = false;
        return;
      }

      // Validar que la imagen no sea null y sea un objeto de archivo
      if (!this.efData.profileImage || !(this.efData.profileImage instanceof File)) {
        Swal.fire({
          icon: 'error',
          title: 'Por favor, selecciona una imagen válida.',
          confirmButtonColor: '#268af7',
        });
        this.loadingFormBtn = false;
        return;
      }

      const imageFile = this.efData.profileImage;

      console.log('Tamaño del archivo:', imageFile); // Agregar esta línea para depurar

      // Validar que el tamaño de la imagen no supere los 2 MB
      if (imageFile.size > 2 * 1024 * 1024) {
        Swal.fire({
          icon: 'error',
          title: 'La imagen seleccionada es demasiado grande. Por favor, selecciona una imagen de menos de 2 MB.',
          confirmButtonColor: '#268af7',
        });
        this.loadingFormBtn = false;
        return;
      }

      // Validar que el formato de la imagen sea jpg o jpeg
      const allowedExtensions = ['jpg', 'jpeg', 'png'];
      const fileName = imageFile.name;
      const fileExtension = fileName.split('.').pop().toLowerCase(); // Extraer la extensión del nombre de archivo

      if (!allowedExtensions.includes(fileExtension)) {
        Swal.fire({
          icon: 'error',
          title: 'El formato de la imagen no es válido. Por favor, selecciona una imagen en formato JPG o PNG.',
          confirmButtonColor: '#268af7',
        });
        this.loadingFormBtn = false;
        return;
      }

      let subtotal = parseFloat(this.precioTef);
      let total = subtotal;

      let charge_info = {
        type: 'card',
        total: total.toFixed(2), // Redondear y luego convertir a cadena
        subtotal: subtotal.toFixed(2), // Redondear y luego convertir a cadena
        comisiones: 0, // Redondear y luego convertir a cadena
        paymentType: data.paymentType,
        efData: this.efData,
      };

      if (data.paymentType === 'card') {
        charge_info.card_token = data.token.id;
        charge_info.cp = data.token.card.address_zip;
        charge_info.nombre_tarjeta = data.token.card.name;
      }

      await this.generateOrder(charge_info);
    },
    async generateOrder(charge_info) {
      try {
        const orderConfirmation = await this.submitCardOrder(charge_info); // Espera a que se resuelva la promesa

        if (orderConfirmation.status) {
          if (charge_info.paymentType != 'oxxo') {
            Swal.fire({
              icon: 'success',
              title: this.translatedText('ef_success_order_title'),
              text: this.translatedText('ef_success_order_text'),
              confirmButtonColor: '#268af7',
            });

            this.$router.push({ name: 'user-profile' });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: this.translatedText(orderConfirmation.data.code),
            confirmButtonColor: '#268af7',
          });

          this.loadingFormBtn = false;

          return;
        }

        if (charge_info.paymentType == 'oxxo') {
          const client_secret = orderConfirmation.payload.order.client_secret;
          const billing_details = {
            name: this.usuario.nombre + ' ' + this.usuario.apellido,
            email: this.usuario.email,
          };

          try {
            await this.confirmOxxoPaymentWithIntent({
              client_secret: client_secret,
              billing_details: billing_details,
            });

            Swal.fire({
              icon: 'success',
              title: this.translatedText('oxxo_payment_success_title'),
              text: this.translatedText('oxxo_payment_success_text'),
              confirmButtonColor: '#268af7',
            });
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Error al procesar el pago en oxxo',
              text: error.message,
              confirmButtonColor: '#268af7', // Color personalizado del botón de confirmación
            });

            this.loadingFormBtn = false;
          }
        }
      } catch (error) {
        console.error('Error al generar la orden:', error);
      }
    },
    updatePaymentType() {
      if (this.isPayingWithOxxo) {
        Swal.fire({
          icon: 'info',
          title: 'Tras dar click en comprar tendrás sólo 24 horas para realizar el pago.',
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          confirmButtonColor: '#268af7',
          cancelButtonColor: '#d33',
        }).then((result) => {
          if (result.isConfirmed) {
            this.paymentType = 'oxxo';
          } else {
            // Si el usuario cancela la alerta, deseleccionamos el checkbox
            this.isPayingWithOxxo = false;
          }
        });
      } else {
        this.paymentType = 'card';
      }
    },
    calculateMaxBirthday() {
      const today = new Date();
      const maxYear = today.getFullYear() - 15;
      const maxMonth = today.getMonth(); // No se suma 1 porque los meses comienzan desde 0
      const maxDay = today.getDate();

      // Crear un objeto de fecha para la fecha máxima
      const maxDate = new Date(maxYear, maxMonth, maxDay);

      return maxDate; // Retornar el objeto de fecha máxima
    },
  },
};
</script>