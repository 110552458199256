<template>
  <div class="blog-categories-page">
    <div class="container">
      <div class="columns is-multiline is-centered">
        <div v-if="publicidad.length > 0" class="column is-6">
          <Advertising />
        </div>
        <div class="column is-12">
          <h2 class="title">Blog</h2>
        </div>
      </div>
      <div v-if="categories.length > 0" class="list">
        <div class="columns is-multiline">
          <div v-for="category in categories" :key="category.id" class="column is-one-third-desktop is-full-mobile is-half-tablet">
            <router-link class="blog-card" :to="{ name: 'blog-categories', params: { id: category.id }, query: { page: 1, size: 9 } }">
              <figure class="image">
                <img :src="category.portada ? category.portada+'/500/500' : imageUrl" />
              </figure>
              <p class="blog-name">{{ category.nombre }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <section class="google-adds">
      <GoogleAdds />
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Advertising from '../../../components/Common/advertising.vue';
import GoogleAdds from '@/components/Common/google-adds.vue';

export default {
  name: 'Blog',
  components: {
    Advertising,
    GoogleAdds,
  },
  data() {
    return {
      imageUrl: 'https://desarrollo.voyalteatro.mx/backend/api/img/render/9339/500/500',
    };
  },
  computed: {
    ...mapState({
      categories: (state) => state.blogs.categories,
      publicidad: (state) => state.dashboard.publicidad,
    }),
  },
  mounted() {
    window.scrollTo(0, 0);
    this.loadBlogsCategories();
  },
  methods: {
    ...mapActions('blogs', ['fetchCategories']),
    loadBlogsCategories() {
      this.fetchCategories();
    },
  },
};
</script>